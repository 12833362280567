import React, { Component } from 'react';
import Paginator from './paginator';


const comics = [
  {
    id: 1,
    title: 'Specially Formulated',
    imageSource: 'positive_paradox_0001.png',
    created: '2019-08-12',
    slug: 'specially-formulated'
  },
  {
    id: 2,
    title: 'Hmm',
    imageSource: 'positive_paradox_0002.png',
    created: '2019-08-19',
    slug: 'hmm'
  },
  {
    id: 3,
    title: 'Meticulous Lawncare',
    imageSource: 'positive_paradox_0003.jpg',
    created: '2019-08-27',
    slug: 'meticulous-lawncare'
  },
  {
    id: 4,
    title: 'Cherrybook Pro',
    imageSource: 'positive_paradox_0004.jpg',
    created: '2019-08-30',
    slug: 'cherrybook-pro'
  }
];

class Main extends Component {

  getComic = () => {
    const { slug } = this.props.match.params;
    let comic = comics[comics.length - 1];

    if (slug) {
      comic = comics.find(c => c.slug === slug);
    }
    return comic;
  }

  getPreviousComic = comic => {
    const currentComic = comic || this.getComic();
    const currentIndex = comics.findIndex(c => c.id === currentComic.id);
    if (currentIndex > 0) {
      return comics[currentIndex - 1];
    }
    return null;
  }

  getNextComic = comic => {
    const currentComic = comic || this.getComic();
    const currentIndex = comics.findIndex(c => c.id === currentComic.id);
    if (currentIndex < comics.length - 1) {
      return comics[currentIndex + 1];
    }
    return null;
  }

  state = {
    currentComic: this.getComic(),
    previousComic: this.getPreviousComic(),
    nextComic: this.getNextComic()
  }

  handleGoPrevious = () => {
    this.setState({
      currentComic: this.state.previousComic,
      previousComic: this.getPreviousComic(this.state.previousComic),
      nextComic: this.state.currentComic
    });
  }

  handleGoNext = () => {
    this.setState({
      currentComic: this.state.nextComic,
      previousComic: this.state.currentComic,
      nextComic: this.getNextComic(this.state.nextComic),
    });
  }

  render() {
    const comic = this.state.currentComic;

    return (
      <main>
        <div className="main-content">
          <h1>{comic.title}</h1>
          <Paginator 
            previousComic={this.state.previousComic}
            nextComic={this.state.nextComic}
            doGoPrevious={this.handleGoPrevious}
            doGoNext={this.handleGoNext}
          />
          <img className="comic-image" src={`/${comic.imageSource}`} alt={comic.title} width="1050" />
        </div>
      </main>
    );
  }
}
 
export default Main;