import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Main from './main';
import './app.css';


class App extends Component {
  render() {
    return (
      <div className="app">
        <header className="app-header">
          <h1><a href="/">Positive Paradox</a></h1>
        </header>
        <Route path="/:slug" component={Main} />
        <Route path="/" exact component={Main} />
        <footer className="app-footer">
          <p>&copy; Copyright 2019 D. Spasovski</p>
        </footer>
      </div>
    );
  }
}

export default App;
