import React from 'react';
import { Link } from 'react-router-dom';


const Paginator = ({ previousComic, nextComic, doGoPrevious, doGoNext }) => {
  return (
    <nav className="paginator">
      <ul>
        {previousComic && <li><Link onClick={doGoPrevious} className="button" to={`/${previousComic.slug}`}>&lt; previous</Link></li>}
        {nextComic && <li><Link onClick={doGoNext} className="button" to={`/${nextComic.slug}`}>next &gt;</Link></li>}
      </ul>
    </nav>
  );
}
 
export default Paginator;